import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Routes from './Router/Routes';
import '../src/assets/scss/main.scss'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Hint from "../src/assets/json/en/hint.json";
import WhatsAppFloatingIcon from './components/WhatsAppFloatingIcon';
import ApexChat from './components/Widgets/ApexChat';
import { isMobileOnly } from 'react-device-detect';
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css';
import 'rsuite/dist/styles/rsuite-default.min.css';
import OfferConsultingForm from './components/Forms/OfferConsultingForm';
import LoginForm from './components/Forms/LoginForm';
import Cookie from './components/Cookie';
import Maintenance from './Maintenance/Maintenance';
import SecurityNotification from './components/Popup/SecurityNotification';

const Footer = lazy(() => import('./components/Footer/Footer'));
const MainHeader = lazy(() => import('./components/Header/MainHeader'));
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryRestrict: true,
            whatsAppMessage: "",
            formConsultingForm: false,
            loginForm: false,
            loginFormCounter: sessionStorage.getItem('loginFormCounter') || 0,
            country: null,
            restrictedText: "",
            is_bottom_true: false,
            linkDisabled: false,
            isVpnTrue: false,
            isResrtictedLink: false,
            forceDisablAll: false
        }
        this.setLinkDisabled = this.setLinkDisabled.bind(this);
    }

    setLinkDisabled(value) {
        this.setState({ linkDisabled: value });
    }

    levenshteinDistance = (s1, s2) => {
        const editDistance = [];
        for (let i = 0; i <= s1.length; i++) {
            editDistance[i] = [i];
        }
        for (let j = 1; j <= s2.length; j++) {
            editDistance[0][j] = j;
        }
        for (let i = 1; i <= s1.length; i++) {
            for (let j = 1; j <= s2.length; j++) {
                const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
                editDistance[i][j] = Math.min(
                    editDistance[i - 1][j] + 1,                    // Deletion
                    editDistance[i][j - 1] + 1,                    // Insertion
                    editDistance[i - 1][j - 1] + cost              // Substitution
                );
            }
        }
        return editDistance[s1.length][s2.length];
    }

    checkDomainSimilarity(domain) {
        const targetDomain = "ovholidays.com";
        if (domain == ("www." + targetDomain)) {
            return false; // Exclude target domain starting with 'www.'
        }
        // Calculate the Levenshtein distance
        let distance = this.levenshteinDistance(domain.toLowerCase(), targetDomain);
        // Determine if the distance is within the allowed threshold
        return distance < 5;
    }

    checkSecurity = async () => {
        let blockList = [
            "ov-holidays.com",
            "ovholiday.com",
            "www.shorturl.at",
            "shorturl.at",
            "staging.ovx.pages.dev"
        ];

        const refererHeader = document.referrer;

        if (!refererHeader) {
            // If there's no referrer
            this.setState({
                isRestrictedLink: true,
                forceDisableAll: true
            });
        } else {
            const refererURL = new URL(refererHeader);
            const refererOrigin = refererURL.hostname;

            if (this.checkDomainSimilarity(refererOrigin) || blockList.includes(refererOrigin)) {
                this.setState({
                    isRestrictedLink: true,
                    forceDisableAll: true
                });
            }
        }
    }

    async componentDidMount() {
        // await this.checkSecurity();
        const referrer = document.referrer;
        const currentDomain = window.location.hostname;
        const allowedDomains = ['allowedsite1.com', 'allowedsite2.com'];
        if (referrer && !referrer.includes(currentDomain) && !allowedDomains.some(domain => referrer.includes(domain))) {
            this.setState({
                isRestrictedLink: true,
                forceDisableAll: true
            });
        }
        // fetch('https://www.viluxurholidays.com/srm/modules/api/rf.php')
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data)
        //         this.setState({ referrer: data.referrer });
        //         if (!data.is_referre_avl) {
        //             this.setState({
        //                 isResrtictedLink: true,
        //                 forceDisablAll: true
        //             })
        //         }
        //     })
        //     .catch(error => {
        //         console.error('Error fetching referrer:', error);
        //     });

        localStorage.setItem('formIsopened', false);
        await this.countryRestrict()
        const WhatsappMessage = Hint.WhatsappMessages[Math.floor(Math.random() * Hint.WhatsappMessages.length)];
        this.setState({
            whatsAppMessage: WhatsappMessage
        })
        var firstLandingPage = localStorage.getItem('first_landing_page');

        if (firstLandingPage && firstLandingPage.includes("travel-blogs")) {

        } else {

            const itemStr = localStorage.getItem("email");

            // If the item doesn't exist, return null
            if (!itemStr) {
                this.setState({
                    // linkDisabled: true
                })
                const currentPath = window.location.pathname;
                if (currentPath !== "/") {
                    // window.location.href = '/';
                }
            } else {
                const item = JSON.parse(itemStr);
                const now = new Date();

                // Compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem("email");
                    this.setState({
                        linkDisabled: true
                    })
                }


                const checkEmail = item.value;
                const currentPath = window.location.pathname;
                if (!checkEmail && currentPath !== "/") {
                    window.location.href = '/';
                }

                if (!checkEmail) {
                    // window.location.href = '/';
                    this.setState({
                        linkDisabled: true
                    })
                }
            }



        }

        // window.addEventListener('scroll', this.handleScroll);


    }

    componentWillUnmount() {
        // Remove event listener when component unmounts
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // Check if scrolled to bottom
        if (windowHeight + scrollTop >= documentHeight) {
            // Trigger function when scrolled to bottom
            // You can call your function here
            this.openConsultingForm();
            this.setState({
                is_bottom_true: true
            })
            // console.log("Scrolled to bottom");
        }

        // Check if scrolled to top
        if (scrollTop === 0) {
            // Trigger function when scrolled to top
            // You can call your function here
            // console.log("Scrolled to top");
            if (this.state.is_bottom_true) {
                this.openConsultingForm();
            }

        }
    }

    countryRestrict = async () => {
        try {
            let clientIpMain;

            const searchParams = new URLSearchParams(window.location.search);
            const paramValue = searchParams.get('__ov_request_key');

            const ipResponse = await fetch("https://ipapi.co/json/", { mode: "cors" });
            const ipData = await ipResponse.json();
            clientIpMain = ipData.ip
            if (ipData.error) {
                const clientIp = await fetch("https://api.ipify.org/?format=json"); //clientIpJson.ip
                const clientIpJson = await clientIp.json();
                const clientSecondIpDetails = await fetch("https://freeipapi.com/api/json/" + clientIpJson.ip); // correct one for now
                clientIpMain = clientIpJson.ip
                // const clientSecondIpDetails = await fetch("https://api.findip.net/"+clientIpJson.ip+"/?token=3af5074e3fb2460c8a2fb61df166c490");

                const clientSecondIpDetailsResult = await clientSecondIpDetails.json();
                this.setState({
                    country: await clientSecondIpDetailsResult.countryCode
                })
                localStorage.setItem("country", this.state.country)
                sessionStorage.setItem("country", this.state.country)

            } else {
                this.setState({
                    country: await ipData.country
                })
                localStorage.setItem("country", this.state.country)
                sessionStorage.setItem("country", this.state.country)
            }

            const checkNetworkStatus = await fetch("https://vpnapi.io/api/" + clientIpMain + "?key=5a3a1bbc65864d78bfb964ddf261ff78");  //https://vpnapi.io/api/8.8.8.8
            const checkNetworkStatusDetailsResult = await checkNetworkStatus.json();
            // console.log(checkNetworkStatusDetailsResult)
            if (checkNetworkStatusDetailsResult.security) {
                if (checkNetworkStatusDetailsResult.security.vpn) {
                    console.log("VPN client")

                    this.setState({
                        linkDisabled: true,
                        isVpnTrue: true,
                        forceDisablAll: true
                    })
                    // window.location.href = '/home';

                } else {
                    console.log("Trust")
                }
            }

            // localStorage.setItem("country_details", ipData)

            const check_local_restriction = sessionStorage.getItem('restricted')
            if (check_local_restriction === "true" || check_local_restriction === null) {
                if (this.state.country === "MV" || this.state.country === "IN") {
                    if (paramValue === "OVPASS321") {
                        console.log("yes")
                        sessionStorage.setItem('restricted', false)
                        this.setState({
                            countryRestrict: true
                        })

                    } else {
                        console.log("No")
                        this.setState({
                            restrictedText: "Restricted"
                        })
                        sessionStorage.setItem('restricted', true)
                        this.setState({
                            countryRestrict: false
                        })
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    openConsultingForm = () => {
        const checkEmail = localStorage.getItem('email');
        const checkConsulting = localStorage.getItem('isSendConsulting');
        const formIsopened = localStorage.getItem('formIsopened');

        if (formIsopened === "false" && checkConsulting == null && this.state.loginFormCounter < 2) {
            let counterSession = this.state.loginFormCounter + 1
            sessionStorage.setItem('loginFormCounter', counterSession)
            this.setState({
                loginFormCounter: counterSession,
            })
            if (checkEmail == null) {
                this.setState({
                    loginForm: true,
                    formConsultingForm: false,
                })

            } else {
                this.setState({
                    loginForm: false,
                    formConsultingForm: true,
                });
            }
        }
    }

    openLoginForm = () => {
        const checkEmail = localStorage.getItem('email');
        if (checkEmail == null) {
            this.setState({
                loginForm: true,
                formConsultingForm: false,
            })

        }
    }

    closeLoginForm = (status, loginCheck) => {
        if (this.state.consultingState) {
            this.setState({
                loginForm: status
            });
        } else {
            this.setState({
                loginForm: status
            });
        }
    };

    closeExitForm = () => {
        this.setState({
            formConsultingForm: false
        })
    }

    render() {
        const { country, restrictedText } = this.state;
        const maintenance = false;

        return (
            <>
                {maintenance ? (
                    <Maintenance />
                ) : (
                    <>
                        {this.state.countryRestrict && this.state.country !== null ? (
                            <GoogleOAuthProvider clientId="603766335050-vf4vmgamnpmcrg900an14ievcj3cvf00.apps.googleusercontent.com">
                                <BrowserRouter >
                                    <ScrollToTop>
                                        <div className="App">
                                            <Suspense fallback={<></>}>
                                                <MainHeader isLinkDisabled={this.state.linkDisabled} forceDisablAll={this.state.forceDisablAll} />
                                            </Suspense>

                                            <div className="body" style={{ "minHeight": "100vh" }}>
                                                <Routes />
                                            </div>
                                            <Suspense fallback={<></>}>
                                                <Footer isLinkDisabled={this.state.linkDisabled} />
                                            </Suspense>
                                            <SecurityNotification isVpnTrue={this.state.isVpnTrue} isResrtictedLink={this.state.isResrtictedLink} />
                                            <Cookie />

                                            {/* WhatsApp icon or ApexChat */}
                                            {/* {!this.state.linkDisabled && (
                                                
                                            )} */}

                                            <WhatsAppFloatingIcon />

                                            {this.state.formConsultingForm &&
                                                <OfferConsultingForm closeExitForm={this.closeExitForm} />
                                            }
                                            {this.state.loginForm &&
                                                <LoginForm closePopup={this.closeLoginForm} />
                                            }

                                        </div>
                                    </ScrollToTop>
                                </BrowserRouter>
                            </GoogleOAuthProvider>


                        ) : (
                            <>{restrictedText}</>
                        )}
                    </>
                )
                }

            </>


        );
    }
}

export default App;

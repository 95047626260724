import React, { useEffect } from 'react';

const TrackingComponent = () => {
    useEffect(() => {

        let pageEntryTime = new Date().getTime();
        const pageData = JSON.parse(sessionStorage.getItem('pageData')) || [];
        pageData.push({
            page: window.location.pathname,
            entryTime: pageEntryTime,
        });
        sessionStorage.setItem('pageData', JSON.stringify(pageData));

        const checkFmdAvail = localStorage.getItem('fmd');
        const sendPageData = async () => {
            try {
                const apiUrl = 'https://www.discoverymaldives.com/experience/upcj.php';
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "pageData": [{
                            page: window.location.pathname,
                            entryTime: pageEntryTime,
                        }],
                        "fmd": checkFmdAvail
                    })
                });

                if (response.ok) {
                    console.log('Data sent successfully');
                } else {
                    console.error('Failed to send data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (checkFmdAvail) {
            sendPageData();
        }


    }, []);

    return null;
};

export default TrackingComponent;
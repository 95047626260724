/*
  Cookie Consent Settings
 */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import Cookies from "universal-cookie";
import CookieConsent from "react-cookie-consent";

export default function CookeSettings () {

    const [decisionMade, setDecisionMade] = useState(true) // start with true to avoid flashing
    const cookies = useMemo(() => new Cookies(), []);

    function gtag() {
        //const window = window.$;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }

    const sendConsent = useCallback((consent) => {
        gtag('consent', 'update', consent);
    }, []);

    useEffect(() => {
        if (cookies.get("OV_HOLIDAYS") !== undefined) {
            setDecisionMade(true)
        } else {
            setDecisionMade(false)
        }
    }, [cookies, setDecisionMade, sendConsent])

    const handleDecision = (outcome) => {
        const consent = {
            'ad_storage': outcome,
            'analytics_storage': outcome,
            'ad_user_data': outcome,
            'ad_personalization': outcome,
        }
        cookies.set("OV_HOLIDAYS", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/",
            domain: "www.ovholidays.com"
        })
        sendConsent(consent)
        setDecisionMade(true)
    }

    //On Cookie Accept
    const onAcc = function (){
        console.log("Cookie Granted By User");
        gtag('js', new Date());
        //TAG
        gtag('config', 'UA-53780159-1');
        handleDecision("granted");
        //Anal4
        gtag('config', 'AW-982388315');
        //Some otherr thing
        gtag('config', 'G-M026NKPLHK');
    };

    return (
        <CookieConsent
        location="bottom"
        buttonText="Sure man!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", zIndex: "9947483647 !important;", zIndex: "9999999" }}
        acceptOnScroll={true}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", alignSelf: "center", display: "none" }}
        expires={150}
        acceptOnScrollPercentage={0.1}
        onAccept={onAcc}
        >
        This website uses cookies to enhance the user experience.{" "}
        <span > By continuing to browse this site, you agree to this use. </span>
        <a href="/privacy-policy" 
         className="Basic-Navlink"
         style={{textDecorationLine: "underline", paddingLeft:"5px" }}
         >Learn More about out our Privacy and Policy
        </a>
        </CookieConsent>
    );
}

import React, { useState, useEffect } from 'react';
import '../../src/assets/scss/component/whatsapp.scss';
import Hint from "../../src/assets/json/en/hint.json";




const WhatsAppFloatingIcon = () => {

    const [whatsappMessage, setWhatsappMessage] = useState(1);

    useEffect(() => {
        const WhatsappMessage = Hint.WhatsappMessages[Math.floor(Math.random() * Hint.WhatsappMessages.length)];
        if (localStorage.getItem("adfrom") !== "false") {
            setWhatsappMessage(WhatsappMessage+ " ("+localStorage.getItem("adfrom")+" "+localStorage.getItem("keyword")+") ")
        } else {
            setWhatsappMessage(WhatsappMessage)
        }
        

    }, []);


    return (
        <a href={`https://api.whatsapp.com/send?phone=9607985454&text=${encodeURIComponent(whatsappMessage)}`} className="whatsapp-icon" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </a>
    );
};

export default WhatsAppFloatingIcon;
import React, { useRef, useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import detectBrowserLanguage from 'detect-browser-language'
import Slider from "react-slick";
import roomCategory from "../../assets/images/icons/category.png"
export function ProductCard({ ImageList, roomDetails, priceData, outerIndex, getThisOffer, getEnqueryHandler }) {
    const [languageStaticContent, setLanguageStaticContent] = useState(require(`../../assets/json/lang/en.json`));

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRefs = useRef([]);


    useEffect(() => {
        const fetchJsonFile = () => {
            try {
                const browserLanguage = detectBrowserLanguage();
                const jsonData = require(`../../assets/json/lang/${browserLanguage}.json`);
                setLanguageStaticContent(jsonData)
                // console.log(jsonData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchJsonFile();
    }, []);

    const handleAfterChange = (currentSlide) => {
        setCurrentSlide(currentSlide);
        // productSliderHandler(outerIndex, currentSlide);
    };

    return (
        <div className="product-card">
            <div className="product-card-card-main-wrapper">
                <div className="product-card-main-image-wrapper">
                    <div className="slider-wrapper-image-container">
                        <Slider
                            className='product-slider'
                            lazyLoad={true}
                            infinite={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={true}
                            swipe={false}
                            afterChange={handleAfterChange}
                            nextArrow={<SampleNextArrowInner />}
                            prevArrow={<SamplePrevArrowInner />}
                            ref={(slider) => (sliderRefs.current[outerIndex] = slider)}
                            responsive={[
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]}>
                            {ImageList && ImageList.map((image, imageIndex) => (
                                <div key={imageIndex} >
                                    <div className="product-room-image" style={{
                                        backgroundPosition: 'center center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundImage: `url(${image.image_full_path})`
                                    }}>

                                    </div>
                                </div>
                            ))}


                        </Slider>
                    </div>
                    <div className='image-count-wrapper'>
                        {currentSlide + 1}/{ImageList && ImageList.length}
                    </div>

                </div>
                <div className="product-card-content-wrapper">
                    <h4 className="product-card-room-title">{roomDetails.room_name}</h4>
                    <div className="product-card-room-details-wrapper">
                        <div className="product-card-room-details-list">
                            <div className="amenities-wrapper">
                                <div className="ameniti">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8l-144 0c-13.3 0-24-10.7-24-24l0-144c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272l144 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z" /></svg>
                                    <h6 className="product-card-room-details-data">
                                        {roomDetails.villa_size} {roomDetails.villa_size_metric}
                                    </h6>
                                </div>
                                <div className="ameniti">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3l0-84.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5l0 21.5c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-26.8C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112l32 0c24 0 46.2 7.5 64.4 20.3zM448 416l0-21.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176l32 0c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2l0 26.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3l0-84.7c-10 11.3-16 26.1-16 42.3zm144-42.3l0 84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2l0 42.8c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-42.8c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112l32 0c61.9 0 112 50.1 112 112z" /></svg>
                                    <h6 className="product-card-room-details-data">
                                        Maximum occupancy {roomDetails.max_adults} adults & {roomDetails.max_children} child
                                    </h6>

                                </div>
                                {/* <div className="ameniti">
                                    <img src={roomCategory} alt="" />
                                    <h6 className="product-card-room-details-data">
                                        {roomDetails.room_category}
                                    </h6>
                                </div> */}

                            </div>

                            <div className='price-button-wrapper'>
                                {priceData &&
                                    <div className="product-card-price-wrapper">
                                        <p className='package-nights'>{priceData.package_night_text} nights on {priceData.meal_plan_data.meal_plan_text}</p>
                                        <h1 className="product-card-discounted-rate">{checkRatesIsdefined(priceData)} <small>/per villa</small></h1>
                                        {priceData.percentage != 0 || priceData.percentage != "0" ? (
                                            <h6 className="product-card-valued-at-rate">Valued at {contractrate(priceData)} <span className='percentage-value'>{priceData.percentage}%</span></h6>
                                        ) : (
                                            <></>
                                        )

                                        }

                                    </div>
                                }

                                <div className="product-card-button-wrapper">{buttonList(priceData, getThisOffer, getEnqueryHandler)}</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="product-card-discount-wrapper">
                    {/* <h6 className="product-card-discount-amount">-{priceData.percentage}%</h6> */}
                </div>
            </div >
        </div >
    );
}

function SampleNextArrowInner(props) {
    const { className, style, onClick } = props;
    return (
        <div className='room-slider-inner-slider-next' onClick={onClick}>
            <i className="fa fa-angle-right" aria-hidden="true" ></i>
        </div>
    );
}

function SamplePrevArrowInner(props) {
    const { className, style, onClick } = props;
    return (
        <div className='room-slider-inner-slider-prev' onClick={onClick}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>

    );
}

//rate checking
function checkRatesIsdefined(subPriceData) {
    return (
        <>
            {subPriceData ? (
                <>
                    USD {subPriceData.package_rate}
                </>
            ) : (
                <>
                    <p>Customizable villa packages available upon request. Personalize your quote for special occasions by contacting us.</p>
                </>
            )}
        </>
    );
}

function contractrate(subPriceData) {
    return (
        <>
            {subPriceData ? (
                <>
                    USD {subPriceData.contract_rate}
                </>
            ) : (
                <>
                    {" "}
                </>
            )}
        </>
    );
}

function buttonList(subPriceData, getThisOffer, getEnqueryHandler) {
    return (
        <>
            {subPriceData ? (
                <>
                    <button className="ov_button primary-button" onClick={getThisOffer}>RESERVE NOW</button>
                    {/* <button className="ov_button link-button">Price Details</button> */}
                </>
            ) : (
                <>
                    <button className="ov_button primary-button" onClick={getEnqueryHandler}>enquire now</button>
                </>
            )}
        </>
    );
}